import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Train, Calendar, Clock, MapPin, X, Loader } from 'lucide-react';

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Container = styled.div`
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  border-radius: 15px;
  padding: 20px;
  color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: ${slideIn} 0.5s ease-out;
  overflow: hidden;
  position: relative;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 10px;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-3px);
  }

  svg {
    margin-right: 10px;
  }
`;

const CancelButton = styled.button`
  background: #ff4757;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  margin-top: 20px;

  &:hover {
    background: #ff6b81;
    animation: ${pulse} 0.5s infinite;
  }

  svg {
    margin-right: 8px;
  }
`;

const TaskProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 0.9rem;

  svg {
    animation: ${rotate} 2s linear infinite;
    margin-right: 10px;
  }
`;

function TaskManager({ currentTask, onCancel }) {
  if (!currentTask) {
    return null;
  }

  return (
    <Container>
      <Title><Train size={24} /> 현재 실행 중인 매크로</Title>
      <InfoGrid>
        <InfoItem>
          <Train size={18} />
          SRT {currentTask.train_number}
        </InfoItem>
        <InfoItem>
          <Calendar size={18} />
          {currentTask.dep_date}
        </InfoItem>
        <InfoItem>
          <Clock size={18} />
          {currentTask.dep_time}
        </InfoItem>
        <InfoItem>
          <MapPin size={18} />
          {currentTask.departure} → {currentTask.arrival}
        </InfoItem>
      </InfoGrid>
      <TaskProgress>
        <Loader size={18} />
        예약 매크로 진행 중..
      </TaskProgress>
      <CancelButton onClick={onCancel}>
        <X size={18} />
        매크로 실행 취소
      </CancelButton>
    </Container>
  );
}

export default TaskManager;