import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, Alert, Button } from 'react-bootstrap';
import AccessControl from './components/AccessControl';
import AdminControl from './components/AdminControl';
import LoginForm from './components/LoginForm';
import SearchForm from './components/SearchForm';
import TrainList from './components/TrainList';
import TaskManager from './components/TaskManager';
import LoadingSpinner from './components/LoadingSpinner';
import { checkLogin, fetchStations, searchTrains, reserveTrain, checkCurrentTask, cancelTask } from './utils/api';
import debounce from 'lodash/debounce';

function App() {
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [stations, setStations] = useState([]);
  const [trains, setTrains] = useState([]);
  const [currentTask, setCurrentTask] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isReserving, setIsReserving] = useState(false);
  const [isDateChanging, setIsDateChanging] = useState(false);

  const today = new Date().toISOString().split('T')[0];  // YYYY-MM-DD 형식의 오늘 날짜
  const [searchParams, setSearchParams] = useState({ departure: '', arrival: '', date: today });
  const handleAccessGranted = (admin) => {
    setIsAccessGranted(true);
    setIsAdmin(admin);
  };

  useEffect(() => {
    fetchStations().then(setStations).catch(() => setMessage('역 목록을 불러오는데 실패했습니다.'));
  }, []);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await checkLogin(credentials);
      if (response.status === 'success') {
        setIsLoggedIn(true);
        setMessage('로그인 성공');
        if (response.current_task) {
          setCurrentTask(response.current_task);
        }
      } else {
        setMessage(response.message);
      }
    } catch (error) {
      setMessage('로그인 실패');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = useCallback(async (params = searchParams) => {
    setIsLoading(true);
    setTrains([]); // 검색 시작 시 기존 결과 초기화
    try {
      const results = await searchTrains({ ...params, ...credentials });
      setTrains(results);
      setMessage('열차 검색 완료');
    } catch (error) {
      setMessage('열차 검색 실패: ' + (error.response?.data?.detail || error.message));
      setTrains([]);
    } finally {
      setIsLoading(false);
    }
  }, [searchParams, credentials]);

    // 디바운스된 검색 함수
    const debouncedSearch = useCallback(
      debounce((params) => handleSearch(params), 300),
      [handleSearch]
    );

  const handleDateChange = async (direction) => {
    setIsDateChanging(true);
    const currentDate = new Date(searchParams.date);
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + (direction === 'next' ? 1 : -1));
    const newDateString = newDate.toISOString().split('T')[0];
    
    const newSearchParams = { ...searchParams, date: newDateString };
    setSearchParams(newSearchParams);
    
    try {
      // 새로운 날짜로 검색 수행
      await handleSearch(newSearchParams);
    } finally {
      setIsDateChanging(false);
    }
  };

  const handleReserve = async (train) => {
    setIsLoading(true);
    try {
      const response = await reserveTrain({
        username: credentials.username,
        password: credentials.password,
        train_code: train.train_code,
        train_number: train.train_number,
        dep_date: train.dep_date,
        dep_time: train.dep_time,
        departure: train.departure,
        arrival: train.arrival
      });
      setMessage(response.message);
      if (response.task_info) {
        setCurrentTask(response.task_info);
        setIsReserving(true);
      }
      await checkTaskStatus();  // 태스크 상태를 즉시 확인
    } catch (error) {
      setMessage('예약 요청 실패: ' + (error.response?.data?.detail || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const checkTaskStatus = async () => {
    setIsLoading(true);
    try {
      const response = await checkCurrentTask(credentials.username);
      if (response.task_info) {
        setCurrentTask(response.task_info);
        setIsReserving(true);
      } else {
        setCurrentTask(null);
        setIsReserving(false);
      }
    } catch (error) {
      setMessage('태스크 확인 실패');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelTask = async () => {
    setIsLoading(true);
    try {
      const response = await cancelTask(credentials.username);
      setMessage(response.message);
      setCurrentTask(null);
      setIsReserving(false);  // isReserving 상태를 false로 설정
      setTrains([]);  // 열차 검색 결과 초기화
    } catch (error) {
      setMessage('태스크 취소 실패');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-lg">
            <Card.Body>
              <h1 className="text-center mb-4">SRT</h1>
              
              {message && isAccessGranted && (
                <Alert variant="info" className="mb-3">
                  {message}
                </Alert>
              )}

              {!isAccessGranted ? (
                <AccessControl onAccessGranted={handleAccessGranted} />
              ) : (
                <>
                  {isAdmin && <AdminControl />}
                  {!isLoggedIn ? (
                    <LoginForm 
                      credentials={credentials} 
                      setCredentials={setCredentials} 
                      onLogin={handleLogin} 
                    />
                  ) : (
                    <>
                      { !currentTask && (<SearchForm 
                        stations={stations} 
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        onSearch={debouncedSearch}
                      />)}
                      {currentTask && (
                        <TaskManager 
                          currentTask={currentTask}
                          onCancel={handleCancelTask} 
                        />
                      )}
                      {!isReserving && !currentTask && (
                        <div className="mt-3">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <Button 
                              variant="outline-secondary" 
                              onClick={() => handleDateChange('prev')}
                              disabled={searchParams.date === today || isDateChanging}
                            >
                              &lt; 이전 날짜
                            </Button>
                            <span>{searchParams.date}</span>
                            <Button 
                              variant="outline-secondary" 
                              onClick={() => handleDateChange('next')}
                              disabled={isDateChanging}
                            >
                              다음 날짜 &gt;
                            </Button>
                          </div>
                          {isLoading ? (
                            <LoadingSpinner />
                          ) : (
                            trains.length > 0 && (
                              <TrainList 
                                trains={trains} 
                                onReserve={handleReserve}
                                searchParams={searchParams}
                              />
                            )
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
