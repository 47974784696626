import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
//const API_URL = 'http://52.79.101.27:8000';

export const checkLogin = async (credentials) => {
  const response = await axios.post(`${API_URL}/check_login`, credentials);
  return response.data;
};

export const fetchStations = async () => {
    const response = await axios.get(`${API_URL}/stations`);
    console.log('Fetched stations:', response.data.stations);  // 디버깅을 위한 로그
    return response.data.stations;
  };

export const searchTrains = async (searchParams, credentials) => {
    const response = await axios.post(`${API_URL}/search_trains`, {
      ...searchParams,
      ...credentials
    });
    return response.data;
  };

  export const reserveTrain = async (reservationData) => {
    const response = await axios.post(`${API_URL}/reserve`, reservationData);
    return response.data;
  };

export const checkCurrentTask = async (username) => {
  const response = await axios.get(`${API_URL}/current_task/${username}`);
  return response.data;
};

export const cancelTask = async (username) => {
  const response = await axios.post(`${API_URL}/cancel_task/${username}`);
  return response.data;
};

export const checkAccessCode = async (code) => {
    const response = await axios.post(`${API_URL}/check_access_code`, { code });
    return response.data;
  };
  
  export const generateAccessCode = async (adminCode) => {
    const response = await axios.post(`${API_URL}/generate_access_code`, { code: adminCode });
    return response.data;
  };
  
  export const getCurrentAccessCode = async () => {
    const response = await axios.get(`${API_URL}/current_access_code`);
    return response.data;
  };
