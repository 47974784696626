import React, { useState } from 'react';
import { Button, Badge, Card, Row, Col, Modal } from 'react-bootstrap';
import { Train, MapPin, Calendar, AlertCircle } from 'lucide-react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const AnimatedCard = styled(Card)`
  animation: ${fadeIn} 0.5s ease-out;
`;

const StyledBadge = styled(Badge)`
  font-size: 0.8rem;
  padding: 0.3em 0.6em;
  margin-right: 0.5rem;
`;

const IconWrapper = styled.span`
  margin-right: 0.5rem;
  color: ${props => props.color || 'inherit'};
`;

function formatDate(dateString) {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  return `${year}-${month}-${day}`;
}

function formatTime(timeString) {
  const hours = timeString.substring(0, 2);
  const minutes = timeString.substring(2, 4);
  return `${hours}:${minutes}`;
}

function TrainList({ trains, onReserve, searchParams }) {
  const [showModal, setShowModal] = useState(false);

  if (trains.length === 0) {
    return (
      <AnimatedCard className="text-center my-5 p-5 bg-light">
        <Train size={48} className="mb-3 text-muted" />
        <h3 className="text-muted">검색된 열차가 없습니다.</h3>
        <p>다른 검색 조건으로 다시 시도해 보세요.</p>
      </AnimatedCard>
    );
  }

  const handleReserveClick = (train) => {
    if (train.general_seat_available) {
      setShowModal(true);
    } else {
      onReserve(train);
    }
  };

  return (
    <div className="mt-4">
      <h2 className="mb-4 text-center">
        <IconWrapper color="#4A90E2"><Train size={32} /></IconWrapper>
        검색 결과
      </h2>
      <AnimatedCard className="mb-4 shadow">
        <Card.Body>
          <h5 className="card-title mb-4">
            <IconWrapper color="#4A90E2"><Train size={24} /></IconWrapper>
            열차 검색 조건
          </h5>
          <Row className="g-3">
            <Col md={4}>
              <div className="d-flex align-items-center">
                <IconWrapper color="#4CAF50"><MapPin size={20} /></IconWrapper>
                <div>
                  <small className="text-muted">출발</small>
                  <p className="mb-0 fw-bold">{searchParams.departure}</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="d-flex align-items-center">
                <IconWrapper color="#F44336"><MapPin size={20} /></IconWrapper>
                <div>
                  <small className="text-muted">도착</small>
                  <p className="mb-0 fw-bold">{searchParams.arrival}</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="d-flex align-items-center">
                <IconWrapper color="#FF9800"><Calendar size={20} /></IconWrapper>
                <div>
                  <small className="text-muted">날짜</small>
                  <p className="mb-0 fw-bold">{searchParams.date}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </AnimatedCard>

      {trains.map((train) => (
        <AnimatedCard key={`${train.train_code}_${train.train_number}`} className="mb-3 shadow-sm">
          <Card.Body>
            <Row className="align-items-center">
              <Col md={3}>
                <h5 className="mb-0">{train.train_name} {train.train_number}</h5>
              </Col>
              <Col md={3}>
                <div className="d-flex align-items-center">
                  <IconWrapper color="#4CAF50"><MapPin size={16} /></IconWrapper>
                  <div>
                    <small className="text-muted">출발</small>
                    <p className="mb-0 fw-bold">{train.departure}</p>
                    <small>{formatDate(train.dep_date)} {formatTime(train.dep_time)}</small>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="d-flex align-items-center">
                  <IconWrapper color="#F44336"><MapPin size={16} /></IconWrapper>
                  <div>
                    <small className="text-muted">도착</small>
                    <p className="mb-0 fw-bold">{train.arrival}</p>
                    <small>{formatDate(train.arr_date)} {formatTime(train.arr_time)}</small>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <StyledBadge bg={train.general_seat_available ? "success" : "danger"}>
                      일반실: {train.general_seat_available ? '가능' : '불가'}
                    </StyledBadge>
                    <StyledBadge bg={train.special_seat_available ? "success" : "danger"}>
                      특실: {train.special_seat_available ? '가능' : '불가'}
                    </StyledBadge>
                  </div>
                  <Button 
                    variant={train.general_seat_available ? "success" : "primary"}
                    size="sm" 
                    onClick={() => handleReserveClick(train)}
                  >
                    {train.general_seat_available ? '확인' : '예약'}
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </AnimatedCard>
      ))}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <IconWrapper color="#FFA000"><AlertCircle size={24} /></IconWrapper>
            알림
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>이건 예약 가능한 자리가 있는데 매크로 돌릴 필요가 있을까..?<br></br>SRT앱 켜서 예약하셈</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TrainList;